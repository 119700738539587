<template>
<v-row class="blackBG">
    <v-col>
<Particles
      id="tsparticles"
      :options="{
            background: {
                color: {
                    value: '#0d47a1'
                },
                opacity: 0
            },
            fpsLimit: 60,
            interactivity: {
                detectsOn: 'canvas',
                events: {
                    onClick: {
                        enable: true,
                        mode: 'push'
                    },
                    onHover: {
                        enable: true,
                        mode: 'grab'
                    },
                    resize: true
                },
                modes: {
                    bubble: {
                        distance: 400,
                        duration: 2,
                        opacity: 0.8,
                        size: 40
                    },
                    push: {
                        quantity: 4
                    },
                    repulse: {
                        distance: 200,
                        duration: 0.4
                    }
                }
            },
            particles: {
                color: {
                    value: '#00BF79'
                },
                links: {
                    color: '#FAF595',
                    distance: 150,
                    enable: true,
                    opacity: 0.3,
                    width: 0.3
                },
                collisions: {
                    enable: true
                },
                move: {
                    direction: 'none',
                    enable: true,
                    outMode: 'bounce',
                    random: false,
                    speed: 3,
                    straight: false
                },
                number: {
                    density: {
                        enable: true,
                        value_area: 1600
                    },
                    value: 80
                },
                opacity: {
                    value: 0.2
                },
                shape: {
                    type: 'circle'
                },
                size: {
                    random: true,
                    value: 5
                }
            },
            detectRetina: true
        }"
    />
        <v-row style="z-index: 100">
            <v-col lg=6 align="left">
                <v-img src="../../assets/argon1.png" max-width=150px class="ml-5" />
            </v-col>
            <v-col lg=6 align="right" class="pr-15">
                <v-btn link to="/home" elevation="2" rounded class="profit darken-2"> Launch Demo </v-btn>
            </v-col>
        </v-row>
        <v-row no-gutters align="center" style="height: 800px; z-index: 100">
            <v-col align="center" class="headline" lg=12>
                <br><br><br><br><span style="" :class="{'gradient': 'true', 'mobile': $vuetify.breakpoint.smAndDown, 'desktop': $vuetify.breakpoint.mdAndUp}">Finance is changing.</span><br><br>Better investments. Higher returns. Faster transactions. 
            </v-col>
        </v-row>
        <v-row no-gutters align="center" style="height: 800px;">
            <v-col align="center" class="headline" lg=12 cols=12>
                      <v-lazy
        :options="{
          threshold: 0.8
        }"
        min-height="200"
        transition="fade-transition"
      >
      <v-row style="z-index: 100">
          <v-col>
                <span :class="{'mobile': $vuetify.breakpoint.smAndDown, 'desktop': $vuetify.breakpoint.mdAndUp}">Welcome to </span><br><br> <v-img src="../../assets/argon1.png" max-width=200px />

          </v-col></v-row></v-lazy>
                
            </v-col>
        </v-row>
        <v-row no-gutters align="center" style="min-height: 800px; z-index: 100">
            <v-col align="center" class="headline" lg=12>
                      <v-lazy
        :options="{
          threshold: .5
        }"
        min-height="200"
        transition="fade-transition"
      >
      <v-row>
          <v-col lg=12 cols=12>
                <span :class="{'gradient': 'true', 'mobile': $vuetify.breakpoint.smAndDown, 'desktop': $vuetify.breakpoint.mdAndUp}">New Assets.</span><br><br> From Cryptocurrencies to Commodities.
          </v-col>
 
          </v-row></v-lazy>
    
        <v-lazy
        :options="{
          threshold: .8
        }"
        min-height="200"
        transition="slide-x-transition"
      >
          <v-row justify="center">
            <v-col class="pt-10" cols=12 lg=2>
               <v-img src="../../assets/splash/Cryptocurrencies.png" max-height="275px" contain> </v-img>
            </v-col>
                        <v-col class="pt-10" cols=12 lg=3>
               <v-img src="../../assets/splash/mirrored_stocks.png" max-height="375px" contain> </v-img>
                </v-col>
          
            <v-col class="pt-10" cols=12 lg=2>
               <v-img src="../../assets/splash/Mirrored_commodities.png" max-height="290px" contain> </v-img>
          </v-col>
          </v-row></v-lazy>
     
                
            </v-col>
        </v-row>

        <v-row no-gutters align="center" style="height: 800px; z-index: 100">
            <v-col align="center" class="headline" lg=12>
                      <v-lazy
        :options="{
          threshold: .5
        }"
        min-height="200"
        transition="fade-transition"
      >
      <v-row>
          <v-col lg=12>
                <span :class="{'gradient': 'true', 'mobile': $vuetify.breakpoint.smAndDown, 'desktop': $vuetify.breakpoint.mdAndUp}">Secured Lending.</span><br><br> Earn and lend on fully backed collateralized loans.
          </v-col>
          
          </v-row></v-lazy>
          <v-lazy
        :options="{
          threshold: .8
        }"
        min-height="200"
        transition="fab-transition"
      >
          <v-row justify="center">
            <v-col class="pt-10" cols=12 lg=3>
               <v-img src="../../assets/splash/makerdao.jpg"> </v-img>
            </v-col>
                        <v-col class="pt-10" cols=12 lg=3>
               <v-img src="../../assets/splash/makerdao.jpg"> </v-img>
                </v-col>
          
            <v-col class="pt-10" cols=12 lg=3>
               <v-img src="../../assets/splash/makerdao.jpg"> </v-img>
          </v-col>
          </v-row></v-lazy>
                
            </v-col>
        
        </v-row>

      <v-row no-gutters align="center" style="height: 1100px; z-index: 100">
            <v-col align="center" class="headline" lg=12>
                      <v-lazy
        :options="{
          threshold: .5
        }"
        min-height="200"
        transition="fade-transition"
      >
      <v-row>
          <v-col lg=4>
              
          </v-col>
          <v-col lg=4>
                <span :class="{'gradient': 'true', 'mobile': $vuetify.breakpoint.smAndDown, 'desktop': $vuetify.breakpoint.mdAndUp}">Decentralized.</span><br><br> Powered by <span class="secondary--text">blockchain.</span>
          </v-col>
            <v-col lg=2>
                
          </v-col>
          </v-row></v-lazy>
        <v-row>
            <v-col align="center" cols=12 lg=6>
                <v-img max-height="12vh" contain src="../../assets/splash/ethereum_logo.png" />
            </v-col>
            <v-col align="center" cols=12 lg=6>
                <v-img max-height="12vh" contain src="../../assets/splash/terra.png" />
            </v-col>
        </v-row>
                
            </v-col>
        </v-row>
    </v-col>
</v-row>
</template>

<script>

export default {
        
    components: {
    },

    data() {
        return {
            
        }
    }

}
</script>

<style scoped>


#tsparticles {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 0
}


.blackBG {
    background-color: #0B0B0B
}

.gradientBGReverse {
    transition: 0.5;
    background: -webkit-linear-gradient(rgba(14, 249, 174, .8) -20%, rgba(11, 11, 11, 1) 100%)
}

.gradientBG {
    transition: 0.5;
background-color: #2d3436;
background-image: linear-gradient(315deg, #2d3436 0%, #000000 74%);}

.mobile {
    font-size: 2em;
    padding: 2px;
}

.mobileImg {
    max-height: "50px"
}

.desktop {
    font-size: 72px;
}

</style>