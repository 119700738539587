var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"blackBG"},[_c('v-col',[_c('Particles',{attrs:{"id":"tsparticles","options":{
            background: {
                color: {
                    value: '#0d47a1'
                },
                opacity: 0
            },
            fpsLimit: 60,
            interactivity: {
                detectsOn: 'canvas',
                events: {
                    onClick: {
                        enable: true,
                        mode: 'push'
                    },
                    onHover: {
                        enable: true,
                        mode: 'grab'
                    },
                    resize: true
                },
                modes: {
                    bubble: {
                        distance: 400,
                        duration: 2,
                        opacity: 0.8,
                        size: 40
                    },
                    push: {
                        quantity: 4
                    },
                    repulse: {
                        distance: 200,
                        duration: 0.4
                    }
                }
            },
            particles: {
                color: {
                    value: '#00BF79'
                },
                links: {
                    color: '#FAF595',
                    distance: 150,
                    enable: true,
                    opacity: 0.3,
                    width: 0.3
                },
                collisions: {
                    enable: true
                },
                move: {
                    direction: 'none',
                    enable: true,
                    outMode: 'bounce',
                    random: false,
                    speed: 3,
                    straight: false
                },
                number: {
                    density: {
                        enable: true,
                        value_area: 1600
                    },
                    value: 80
                },
                opacity: {
                    value: 0.2
                },
                shape: {
                    type: 'circle'
                },
                size: {
                    random: true,
                    value: 5
                }
            },
            detectRetina: true
        }}}),_c('v-row',{staticStyle:{"z-index":"100"}},[_c('v-col',{attrs:{"lg":"6","align":"left"}},[_c('v-img',{staticClass:"ml-5",attrs:{"src":require("../../assets/argon1.png"),"max-width":"150px"}})],1),_c('v-col',{staticClass:"pr-15",attrs:{"lg":"6","align":"right"}},[_c('v-btn',{staticClass:"profit darken-2",attrs:{"link":"","to":"/home","elevation":"2","rounded":""}},[_vm._v(" Launch Demo ")])],1)],1),_c('v-row',{staticStyle:{"height":"800px","z-index":"100"},attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"headline",attrs:{"align":"center","lg":"12"}},[_c('br'),_c('br'),_c('br'),_c('br'),_c('span',{class:{'gradient': 'true', 'mobile': _vm.$vuetify.breakpoint.smAndDown, 'desktop': _vm.$vuetify.breakpoint.mdAndUp}},[_vm._v("Finance is changing.")]),_c('br'),_c('br'),_vm._v("Better investments. Higher returns. Faster transactions. ")])],1),_c('v-row',{staticStyle:{"height":"800px"},attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"headline",attrs:{"align":"center","lg":"12","cols":"12"}},[_c('v-lazy',{attrs:{"options":{
          threshold: 0.8
        },"min-height":"200","transition":"fade-transition"}},[_c('v-row',{staticStyle:{"z-index":"100"}},[_c('v-col',[_c('span',{class:{'mobile': _vm.$vuetify.breakpoint.smAndDown, 'desktop': _vm.$vuetify.breakpoint.mdAndUp}},[_vm._v("Welcome to ")]),_c('br'),_c('br'),_vm._v(" "),_c('v-img',{attrs:{"src":require("../../assets/argon1.png"),"max-width":"200px"}})],1)],1)],1)],1)],1),_c('v-row',{staticStyle:{"min-height":"800px","z-index":"100"},attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"headline",attrs:{"align":"center","lg":"12"}},[_c('v-lazy',{attrs:{"options":{
          threshold: .5
        },"min-height":"200","transition":"fade-transition"}},[_c('v-row',[_c('v-col',{attrs:{"lg":"12","cols":"12"}},[_c('span',{class:{'gradient': 'true', 'mobile': _vm.$vuetify.breakpoint.smAndDown, 'desktop': _vm.$vuetify.breakpoint.mdAndUp}},[_vm._v("New Assets.")]),_c('br'),_c('br'),_vm._v(" From Cryptocurrencies to Commodities. ")])],1)],1),_c('v-lazy',{attrs:{"options":{
          threshold: .8
        },"min-height":"200","transition":"slide-x-transition"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pt-10",attrs:{"cols":"12","lg":"2"}},[_c('v-img',{attrs:{"src":require("../../assets/splash/Cryptocurrencies.png"),"max-height":"275px","contain":""}})],1),_c('v-col',{staticClass:"pt-10",attrs:{"cols":"12","lg":"3"}},[_c('v-img',{attrs:{"src":require("../../assets/splash/mirrored_stocks.png"),"max-height":"375px","contain":""}})],1),_c('v-col',{staticClass:"pt-10",attrs:{"cols":"12","lg":"2"}},[_c('v-img',{attrs:{"src":require("../../assets/splash/Mirrored_commodities.png"),"max-height":"290px","contain":""}})],1)],1)],1)],1)],1),_c('v-row',{staticStyle:{"height":"800px","z-index":"100"},attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"headline",attrs:{"align":"center","lg":"12"}},[_c('v-lazy',{attrs:{"options":{
          threshold: .5
        },"min-height":"200","transition":"fade-transition"}},[_c('v-row',[_c('v-col',{attrs:{"lg":"12"}},[_c('span',{class:{'gradient': 'true', 'mobile': _vm.$vuetify.breakpoint.smAndDown, 'desktop': _vm.$vuetify.breakpoint.mdAndUp}},[_vm._v("Secured Lending.")]),_c('br'),_c('br'),_vm._v(" Earn and lend on fully backed collateralized loans. ")])],1)],1),_c('v-lazy',{attrs:{"options":{
          threshold: .8
        },"min-height":"200","transition":"fab-transition"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pt-10",attrs:{"cols":"12","lg":"3"}},[_c('v-img',{attrs:{"src":require("../../assets/splash/makerdao.jpg")}})],1),_c('v-col',{staticClass:"pt-10",attrs:{"cols":"12","lg":"3"}},[_c('v-img',{attrs:{"src":require("../../assets/splash/makerdao.jpg")}})],1),_c('v-col',{staticClass:"pt-10",attrs:{"cols":"12","lg":"3"}},[_c('v-img',{attrs:{"src":require("../../assets/splash/makerdao.jpg")}})],1)],1)],1)],1)],1),_c('v-row',{staticStyle:{"height":"1100px","z-index":"100"},attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"headline",attrs:{"align":"center","lg":"12"}},[_c('v-lazy',{attrs:{"options":{
          threshold: .5
        },"min-height":"200","transition":"fade-transition"}},[_c('v-row',[_c('v-col',{attrs:{"lg":"4"}}),_c('v-col',{attrs:{"lg":"4"}},[_c('span',{class:{'gradient': 'true', 'mobile': _vm.$vuetify.breakpoint.smAndDown, 'desktop': _vm.$vuetify.breakpoint.mdAndUp}},[_vm._v("Decentralized.")]),_c('br'),_c('br'),_vm._v(" Powered by "),_c('span',{staticClass:"secondary--text"},[_vm._v("blockchain.")])]),_c('v-col',{attrs:{"lg":"2"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"align":"center","cols":"12","lg":"6"}},[_c('v-img',{attrs:{"max-height":"12vh","contain":"","src":require("../../assets/splash/ethereum_logo.png")}})],1),_c('v-col',{attrs:{"align":"center","cols":"12","lg":"6"}},[_c('v-img',{attrs:{"max-height":"12vh","contain":"","src":require("../../assets/splash/terra.png")}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }